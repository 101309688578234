import React from 'react';
import cn from 'classnames';

import stylesCommon from '@StylesJson/common.module.json';

export const PreloaderTypes = {
	pulse: 'pulse',
	balls: 'balls',
};

const Preloader = ({ className, type = 'pulse', fixed = false, loading = true, style, ...props }) => {

	return (
  <div
    className={cn(
				stylesCommon.preloader,
				stylesCommon[`preloader-${PreloaderTypes[type]}`],
				!loading && stylesCommon.preloaderHidden,
				{
					[stylesCommon.preloaderFixed]: fixed,
				},
				className
			)}
    style={{ ...style }}
    {...props}
		>
    {type === 'pulse' ? (
      <>
        <div />
        <div />
      </>
			) : (
  <>
    <div />
    <div />
    <div />
    <div />
  </>
			)}
  </div>
	);
};

export default Preloader;
