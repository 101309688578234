import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _upperFirst from 'lodash/upperFirst';
import _isNull from 'lodash/isNull';

import stylesCommon from '@StylesJson/common.module.json';

export const ButtonSizes = {
  md: 'md',
  lg: 'lg',
  xl: 'xl',
};

const Button = React.forwardRef((props, ref) => {
  const {
    theme = 'primary',
    size = 'md',
    fill = false,
    fit = false,
    link = null,
    children,
    prefix: PrefixComponent = null,
    className = {},
    ...other
  } = props;

  const Component = _isNull(link) ? 'button' : 'a';

  return (
    <Component
      className={cn(
        stylesCommon.button,
        !!theme && stylesCommon[`buttonTheme${_upperFirst(theme)}`],
        stylesCommon[`buttonSize${_upperFirst(size)}`],
        fill && stylesCommon.buttonFill,
        fit && stylesCommon.buttonFit,
        ...className,
      )}
      {...{ ...other, ...(!_isNull(link) && { href: link }) }}
      ref={ref}
      data-ui-control="button"
    >
      {PrefixComponent && (
        <div className={stylesCommon.buttonPrefix}>
          <PrefixComponent />
        </div>
      )}
      <div className={stylesCommon.buttonContent}>{children}</div>
    </Component>
  );
});

// const Button = () => {
//
// }

Button.propsTypes = {
  theme: PropTypes.oneOf(['primary', 'secondary', 'success']),
  size: PropTypes.oneOf(['xs', 'sm', 'md']),
  fill: PropTypes.bool,
};

export default Button;
