import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import _capitalize from 'lodash/capitalize';

import stylesCommon from '@StylesJson/common.module.json';

const Label = ({
	component: Component = 'label',
	size = 'md',
	children,
	className = {},
	...props
}) => {
	return (
  <Component
    className={cn(
				stylesCommon.label,
				stylesCommon[`labelSize${_capitalize(size)}`],
				...className
			)}
    {...props}
    data-ui-control="label"
		>
    {children}
  </Component>
	);
};

Label.propsTypes = {
	size: PropTypes.oneOf(['xs', 'sm', 'md']),
};

export default Label;
