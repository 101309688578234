import _uniq from 'lodash/uniq';
import _without from 'lodash/without';
import _isNull from 'lodash/isNull';
import URL from 'url-js';
import parse from 'html-react-parser';

import { windowScrollUp } from '@JsReact/helpers/client';
import { toRussianMoney } from '@Js/helpers/money';

const imagePreloaderSrc = (e) => {
  e.target.src = `${PRODUCTION_URL}/forum/images/icons/image-preloader.svg`;
};

const categoriesToBreadcrumbsItems = (current, categories) => {
  const items = [];

  const getChildrenCategories = (array, category) => {
    if (category.id !== parseInt(current, 10)) {
      array.push({
        name: category.category_name || category.name,
        link: `/category/${category.category_id || category.id}`,
        router: false,
      });

      getChildrenCategories(array, category.children[0]);
    } else {
      array.push({
        name: category.category_name || category.name,
        link: `/category/${category.category_id || category.id}`,
        router: false,
      });
    }
  };

  getChildrenCategories(items, categories[0]);

  return items;
};

const parseUrlParamArray = (value) => (value ? value.split(';') : null);

const deleteUrlParamsFn = (removeKeys, queryParams) => {
  removeKeys.forEach((key) => {
    queryParams.delete(key);
  });

  return queryParams;
};

export const deleteUrlParams = (removeKeys, reactRouterHistory = null, scrollUp = false) => {
  let queryParams = new URLSearchParams(window.location.search);
  queryParams = deleteUrlParamsFn(removeKeys, queryParams);
  queryParams.sort();
  reactRouterHistory
    ? reactRouterHistory.push({
      pathname: window.location.pathname,
      search: `?${queryParams.toString()}`,
    })
    : window.history.pushState('', '', `?${queryParams.toString()}`);

  scrollUp && windowScrollUp();
};

export const clearUrlParams = (except = [], reactRouterHistory = null) => {
  const queryParams = new URLSearchParams(window.location.search);
  const keysToDelete = [];
  const queryKeys = queryParams.keys();
  // eslint-disable-next-line no-restricted-syntax
  for (const key of queryKeys) {
    !except.includes(key) && keysToDelete.push(key);
  }

  keysToDelete.forEach((key) => {
    queryParams.delete(key);
  });

  queryParams.sort();
  reactRouterHistory
    ? reactRouterHistory.push({
      pathname: window.location.pathname,
      search: `?${queryParams.toString()}`,
    })
    : window.history.pushState('', '', `?${queryParams.toString()}`);
};

export const changeUrlParams = (
  params,
  reactRouterHistory = null,
  saveKeys = [],
  scrollUp = false,
) => {
  const queryParams = new URLSearchParams(window.location.search);
  const customQueryParams = {};

  if (saveKeys.length !== 0) {
    saveKeys.forEach((key) => {
      const queryValue = queryParams.get(key);
      if (queryValue) {
        customQueryParams[key] = queryValue;
      }
    });
  }

  params.forEach((param) => {
    if (!_isNull(param.value) || param.value !== '') {
      customQueryParams[param.key] = param.value;
    }
  });

  // queryParams.sort();
  reactRouterHistory
    ? reactRouterHistory.push({
      pathname: window.location.pathname,
      search: `?${URL.fromObject(customQueryParams)}`,
    })
    : window.history.pushState('', '', `?${URL.fromObject(customQueryParams)}`);

  scrollUp && windowScrollUp();
};

const toggleUrlParam = (
  name,
  value,
  toggle = true,
  reactRouterHistory = null,
  removeKeys = [],
  scrollUp = false,
) => {
  let queryParams = new URLSearchParams(window.location.search);
  toggle ? queryParams.set(name, value) : queryParams.delete(name);

  if (removeKeys.length !== 0) {
    queryParams = deleteUrlParamsFn(removeKeys, queryParams);
  }
  queryParams.sort();
  reactRouterHistory
    ? reactRouterHistory.push({
      pathname: window.location.pathname,
      search: `?${queryParams.toString()}`,
    })
    : window.history.pushState('', '', `?${queryParams.toString()}`);

  scrollUp && windowScrollUp();
};

const toggleUrlParamArray = (
  name,
  item,
  toggle = true,
  reactRouterHistory = null,
  removeKeys = [],
  scrollUp = false,
) => {
  let queryParams = new URLSearchParams(window.location.search);
  const currentUrlParam = parseUrlParamArray(queryParams.get(name)) || [];

  const newValue = (toggle ? _uniq([...currentUrlParam, item]) : _without(currentUrlParam, item))
    .sort()
    .join(';');
  newValue ? queryParams.set(name, newValue) : queryParams.delete(name);

  if (removeKeys.length !== 0) {
    queryParams = deleteUrlParamsFn(removeKeys, queryParams);
  }
  queryParams.sort();
  reactRouterHistory
    ? reactRouterHistory.push({
      pathname: window.location.pathname,
      search: `?${queryParams.toString()}`,
    })
    : window.history.pushState('', '', `?${queryParams.toString()}`);

  scrollUp && windowScrollUp();
};

export const formatPrice = (price = 0, replace = true) => {
  if (replace && price === 0) {
    return 'По договоренности';
  }

  return parse(toRussianMoney(price));
};

export const seoUrlCustom = (prefix, id, postfix = '.html') => `/forum/-${prefix}${id}${postfix}`;

export const urlParamEscape = (value) => encodeURIComponent(value).replace(/%20/g, '+');

export {
  imagePreloaderSrc,
  categoriesToBreadcrumbsItems,
  toggleUrlParam,
  toggleUrlParamArray,
  parseUrlParamArray,
};
