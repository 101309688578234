import { scrollTo } from 'scroll-js';

export const windowScrollUp = (callback = null) => {
	scrollTo(document.body, { top: 0, duration: 1000, easing: 'ease-in-out' }).then(() => {
		if (callback) {
			callback();
		}
	});
};

export const scrollFixed = (toggle) => {
	if (toggle) {
		const scrollY =
			window.pageYOffset ||
			document.documentElement.scrollTop ||
			document.body.scrollTop ||
			0;

		document.body.style.position = 'fixed';
		document.body.style.top = `-${scrollY}px`;
	} else {
		const scrollY = document.body.style.top;
		document.body.style.position = '';
		document.body.style.top = '';
		window.scrollTo(0, parseInt(scrollY || '0') * -1);
	}
};
