import React from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import PropTypes from 'prop-types';

import Preloader from '@JsReact/ui/heplers/Preloader';
import SvgIcon from '@JsComponents/utils/SvgIcon';
import styles from './Modal.react-module.scss';

const ModalInstance = ({
  className,
  wrapperClassName,
  overlayClassName,
  children,
  onClose,
  isLoading,
}) => {
  const contentRef = React.useRef();

  const handleClickAway = React.useCallback(({ target }) => {
    if (
      !!contentRef.current
      && !isLoading
      && !(contentRef.current === target || contentRef.current.contains(target))
    ) {
      onClose();
    }
  }, [isLoading, contentRef]);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={cn(styles.overlay, overlayClassName)}
      onClick={handleClickAway}
    >
      {
        isLoading && (
          <div className={styles.preloader}>
            <Preloader className={styles.preloaderInstance} />
          </div>
        )
      }
      <div
        className={cn(styles.wrapper, wrapperClassName)}
      >
        <div
          className={cn(styles.content, className, {
            loading: isLoading,
          })}
          ref={contentRef}
        >
          {
            !isLoading && (
              <>
                <button
                  onClick={onClose}
                  type="button"
                  className={styles.close}
                >
                  <SvgIcon name="times" />
                </button>
                {children}
              </>
            )
          }
        </div>
      </div>
    </div>
  );
};

const ModalPortal = (props) => createPortal(<ModalInstance {...props} />, document.body);

ModalPortal.propTypes = {
  duration: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default ModalPortal;
