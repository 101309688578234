import { gql } from '@apollo/client';

export const GET_SEARCH_LOTS_QUERY = gql`
	query Search(
		$keywords: String
		$category_id: [Int]
		$purchase: [Int]
		$catalog: [Int]
		$owner: [Int]
		$productcat: [Int]
		$tag: [Int]
		$sort_type: SortTypeEnum
		$sort_dir: SortDirEnum
		$prop: [SearchPropType]
		$price_from: Float
		$price_to: Float
		$limit: Int
		$offset: Int
		$isCatalog: Boolean!
	) {
		search(
			keywords: $keywords
			category: $category_id
			productcat: $productcat
			catalog: $catalog
			purchase: $purchase
			owner: $owner
			tag: $tag
			sort_dir: $sort_dir
			sort_type: $sort_type
			price_from: $price_from
			price_to: $price_to
			prop: $prop
			limit: $limit
			offset: $offset
			is_catalog: $isCatalog
		) {
			count
			is_translit_detected
			keywords
			items {
				...lotInfo
				__typename
			}
			__typename
		}
	}

	fragment lotInfo on Lot {
		lot_id
		lot_name
		lot_tag_id
		isFavorite
		series @include(if: $isCatalog) {
			name
			rows {
				num
				items {
					value
					ordered
				}
			}
		}
		itemImages {
			pic_id
			url
			url_thumb
			__typename
		}
		price {
			amount
			__typename
		}
		purchase {
			purchase_id
		}
		attributes {
			properties {
				values
				label
				__typename
			}
			features {
				values
				label
				__typename
			}
			__typename
		}
		__typename
	}
`;

export const GET_SEARCH_ONLY_FILTERS_CATEGORIES_QUERY = gql`
	query SearchOnlyFiltersCategories(
		$keywords: String
		$category_id: [Int]
		$purchase: [Int]
		$catalog: [Int]
		$owner: [Int]
		$productcat: [Int]
		$tag: [Int]
		$sort_type: SortTypeEnum
		$sort_dir: SortDirEnum
		$prop: [SearchPropType]
		$price_from: Float
		$price_to: Float
		$limit: Int
		$offset: Int
		$isCatalog: Boolean!
	) {
		search(
			keywords: $keywords
			category: $category_id
			productcat: $productcat
			catalog: $catalog
			purchase: $purchase
			owner: $owner
			tag: $tag
			sort_dir: $sort_dir
			sort_type: $sort_type
			price_from: $price_from
			price_to: $price_to
			prop: $prop
			limit: $limit
			offset: $offset
		) {
			filters_category_tree @skip(if: $isCatalog) {
				id
				name
				parent_id
				has_child
				children {
					id
					name
					parent_id
					has_child
					children {
						id
						name
						parent_id
						has_child
						children {
							id
							name
							parent_id
							has_child
							children {
								id
							}
						}
					}
				}
				__typename
			}
		}
	}
`;

export const GET_SEARCH_FILTERS_QUERY = gql`
	query SearchFilters(
		$keywords: String
		$category_id: [Int]
		$purchase: [Int]
		$catalog: [Int]
		$owner: [Int]
		$productcat: [Int]
		$tag: [Int]
		$prop: [SearchPropType]
		$price_from: Float
		$price_to: Float
		$isCatalog: Boolean!
	) {
		search(
			keywords: $keywords
			category: $category_id
			productcat: $productcat
			catalog: $catalog
			purchase: $purchase
			owner: $owner
			tag: $tag
			price_from: $price_from
			price_to: $price_to
			prop: $prop
			is_catalog: $isCatalog
		) {
			max_cost
			min_cost
			filters_purchase @skip(if: $isCatalog) {
				name
				id
				total
			}
			filters_productcat @skip(if: $isCatalog) {
				name
				id
				total
			}
			filters_owner @skip(if: $isCatalog) {
				name
				id
				total
			}
			filters_tag {
				name
				id
			}
			filters_prop {
				name
				values {
					name
				}
			}
		}
	}
`;

export const GET_SEARCH_FILTERS_FULL_QUERY = gql`
	query SearchFiltersFull(
		$keywords: String
		$category_id: [Int]
		$purchase: [Int]
		$catalog: [Int]
		$owner: [Int]
		$productcat: [Int]
		$tag: [Int]
		$prop: [SearchPropType]
		$price_from: Float
		$price_to: Float
		$isCatalog: Boolean!
	) {
		search(
			keywords: $keywords
			category: $category_id
			productcat: $productcat
			catalog: $catalog
			purchase: $purchase
			owner: $owner
			tag: $tag
			price_from: $price_from
			price_to: $price_to
			prop: $prop
			is_catalog: $isCatalog
		) {
			max_cost
			min_cost
			filters_category_tree @skip(if: $isCatalog) {
				id
				name
				parent_id
				has_child
				children {
					id
					name
					parent_id
					has_child
					children {
						id
						name
						parent_id
						has_child
						children {
							id
							name
							parent_id
							has_child
							children {
								id
							}
						}
					}
				}
			}
			filters_purchase @skip(if: $isCatalog) {
				name
				id
				total
			}
			filters_productcat @skip(if: $isCatalog) {
				name
				id
				total
			}
			filters_owner @skip(if: $isCatalog) {
				name
				id
				total
			}
			filters_tag {
				name
				id
			}
			filters_prop {
				name
				values {
					name
				}
			}
		}
	}
`;

export const GET_SEARCH_FILTERS_CATEGORIES_QUERY = gql`
	query SearchFiltersCategories($category_id: [Int]) {
		search(category: $category_id) {
			filters_category_tree {
				id
				name
				parent_id
				has_child
				children {
					id
					name
					parent_id
					has_child
					children {
						id
						name
						parent_id
						has_child
						children {
							id
							name
							parent_id
							has_child
							children {
								id
							}
						}
					}
				}
				__typename
			}
		}
	}
`;

export const GET_SEARCH_FILTERS_CATEGORIES_NESTED_QUERY = gql`
	query SearchFiltersCategories($category_id: [Int]) {
		search(category: $category_id, nested_category: true) {
			filters_category_tree {
				id
				name
				parent_id
				has_child
				children {
					id
					name
					parent_id
					has_child
					children {
						id
						name
						parent_id
						has_child
						children {
							id
							name
							parent_id
							has_child
							children {
								id
							}
						}
					}
				}
				__typename
			}
		}
	}
`;
