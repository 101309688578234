import React, {Component} from 'react';
import classNames from 'classnames';

const SvgIcon = ({name, classes}) => {
	return (
		<svg className={classNames('svg-icon', classes)}>
			<use xlinkHref={`#icon-${name}`} />
		</svg>
	);
};

export default SvgIcon;