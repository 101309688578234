import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import client from '@Js/client/ApolloClient';
import { BrowserRouter } from 'react-router-dom';
import _snakeCase from 'lodash/snakeCase';
import BaseWrapper from '@JsReactComponents/BaseWrapper/BaseWrapper';

const renderModule = (
	Module,
	moduleName,
	ssr = true,
	clientProps = {},
	router = true,
	fetching = true
) => {
	const id = `${_snakeCase(moduleName).toUpperCase()}_REACT_MODULE`;

	const ModuleWithProps = () => {
		return (
			<BaseWrapper>
				<Module {...window[`__SERVER_PROPS_${id}`]} />
			</BaseWrapper>
		);
	};

	const ModuleWithFetching = () => {
		return fetching ? (
			<ApolloProvider client={client(id, clientProps)}>
				<ModuleWithProps />
			</ApolloProvider>
		) : (
			<ModuleWithProps />
		);
	};

	const ModuleWithRouter = () => {
		return router ? (
			<BrowserRouter>
				<ModuleWithFetching />
			</BrowserRouter>
		) : (
			<ModuleWithFetching />
		);
	};

	if (document.getElementById(id)) {
		ReactDOM[ssr ? 'hydrate' : 'render'](<ModuleWithRouter />, document.getElementById(id));
	} else if (document.getElementById(`${id}_FALLBACK`)) {
		ReactDOM.render(<ModuleWithRouter />, document.getElementById(`${id}_FALLBACK`));
	}
};

export { renderModule };
