import { gql } from '@apollo/client';

const GET_CATEGORIES_TOP_LEVEL_QUERY = gql`
	query CategoriesTopLevel {
	  categories(parent_id: 0) {
	    category_id
	    category_name
	  }
	}
`;

const GET_CHILDREN_CATEGORIES_QUERY = gql`
	query ChildrenCategories($parent_id: Int, $children: Boolean!) {
	  categories(parent_id: $parent_id) {
	    category_id
	    category_name
      categories @include(if: $children) {  
		    category_id
		    category_name
		  }
	  }
	}
`;

const GET_CATEGORY_TREE = gql`
	query CategoryBreadcrumbs($category_id: Int) {
	  categories(category_id: $category_id) {
	    category_id
	    category_name
	    parent{
	      category_id
	      category_name
	      parent{
	        category_id
	        category_name
	        parent{
	          category_id
	          category_name
	        }
	      }
	    }
	   }
	}
`;

const GET_CATEGORY_FILTER_TREE_QUERY = gql`
	query ChildrenCategories($category_id: Int, $children: Boolean!) {
    categories(category_id: $category_id) {
      category_id
      category_name,
      parent {
        category_id,
        category_name
        parent {
          category_id,
          category_name,
          parent {
            category_id,
            category_name
          }
        }
      }
      categories @include(if: $children) {  
        category_id
        category_name
      }
    

    }
  
  }
`;

export const GET_CATEGORY_BY_ID_QUERY = gql`
	query categoryById($category_id: [Int]) {
    categories(category_id: $category_id) {
      category_id
      category_name,
    }
  }
`;

export const GET_CHILDREN_CATEGORIES_BY_ID_CATEGORY_QUERY = gql`
	query childrenCategoriesByIdCategory($category_id: [Int]) {
    categories(category_id: $category_id) {
      category_id
      category_name,
      
    }
  }
`;


export {
	GET_CATEGORIES_TOP_LEVEL_QUERY,
	GET_CHILDREN_CATEGORIES_QUERY,
	GET_CATEGORY_TREE,
	GET_CATEGORY_FILTER_TREE_QUERY
}