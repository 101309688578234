import { gql } from '@apollo/client';

export const GET_SUGGESTION_ALL = gql`
	query suggestionsAll($keywords: String, $limit: Int, $wordsLimit: Int) {
		SuggestCategories(keywords: $keywords, limit: $limit) {
			category_id
			category_name
		}
		SuggestWords(keywords: $keywords, limit: $wordsLimit) {
			word
		}
		SuggestOwner(keywords: $keywords, limit: $limit) {
			user_id
			username
			avatar
		}
		SuggestPurchases(keywords: $keywords, limit: $limit) {
			purchase_id
			purchase_name
			topic_id
		}
		SuggestLots(keywords: $keywords, limit: $limit) {
			lot_id
			lot_name
			lot_price
			lot_image_thumb
		}
	}
`;

export const GET_USERS_BY_USERNAME = gql`
  query GetUsersByUsername($username: String) {
    UsersSearch(nickname: $username) {
      user_id
      username
      user_avatar_link
    }
  }
`;
