import React from 'react';

export const CommonContext = React.createContext();

export const constantsCommonReducer = {
	SHOW_LOT_MODAL: 'SHOW_LOT_MODAL',
	HIDDEN_LOT_MODAL: 'HIDDEN_LOT_MODAL',
	SET_PAGE_ERROR: 'SET_PAGE_ERROR',
};

export const initialStateCommonReducer = {
	lotModalId: null,
	isError: false,
	errorText: null,
	errorComponent: null,
	errorData: null,
};

export const commonReducer = (state, action) => {
	switch (action.type) {
		// case constantsCommonReducer.SHOW_LOT_MODAL: {
		// 	return {
		// 		...state,
		// 		isError: true,
		// 		errorText: action.errorText || null,
		// 		errorComponent: action.errorComponent || null,
		// 		errorData: action.errorData || null,
		// 	}
		// }
		case constantsCommonReducer.SHOW_LOT_MODAL: {
			return {
				...state,
				lotModalId: action.lotId,
			};
		}
		case constantsCommonReducer.HIDDEN_LOT_MODAL: {
			return {
				...state,
				lotModalId: null,
			};
		}
		default:
			return state;
	}
};
