import React from 'react';
import cn from 'classnames';
import parse from 'html-react-parser';
import { useClickAway, useDebounce, useMount } from 'react-use';
import { useApolloClient } from '@apollo/client';
import _isNull from 'lodash/isNull';
import _escapeRegExp from 'lodash/escapeRegExp';
import _filter from 'lodash/filter';

import Button from '@JsReact/ui/controls/Button/Button';
import SvgIcon from '@JsComponents/utils/SvgIcon';
import Input from '@JsReact/ui/controls/Input/Input';
import { useMedia, useQueryParams, useScrollFixed } from '@JsReact/helpers/hooks';
import Modal from '@JsReact/ui/components/Modal/Modal';
import { GET_SUGGESTION_ALL } from '@Js/api/helpers/queries';
import {
  formatPrice,
  imagePreloaderSrc,
  seoUrlCustom,
  urlParamEscape,
} from '@JsReact/helpers/data';
import stylesCommon from '@StylesJson/common.module.json';
import MediaWrap from '@JsReact/ui/heplers/MediaWrap';
import { ModalMain } from '@JsReact/ui/components/Modal';
import styles from './styles/header-search.react-module.scss';

const SearchSuggestionItem = ({
  data: Data,
  meta: Meta = null,
  title,
  link,
  onClick = null,
  focus = false,
}) => {
  const Component = _isNull(onClick) ? 'a' : 'div';
  return (
    <Component
      href={_isNull(onClick) ? link : undefined}
      onClick={onClick}
      className={cn(styles.suggestionsItem, focus && 'focused')}
      title={title}
    >
      <div className={styles.suggestionsItemMeta}>{!_isNull(Meta) && <Meta />}</div>
      <div className={styles.suggestionsItemTitle}>
        <Data />
      </div>
      <button
        type="button"
        className={styles.suggestionsItemIcon}
      >
        <SvgIcon name="angle-right" />
      </button>
    </Component>
  );
};

const SearchSuggestionHistory = ({
  keyword,
  regex,
  handleDelete,
  focus = false,
}) => (
  <a
    href={`/search?keywords=${keyword}`}
    className={cn(styles.suggestionsItem, focus && 'focused')}
    title={keyword}
  >
    <div className={styles.suggestionsItemMeta}>
      <SvgIcon name="history" />
    </div>
    <div className={styles.suggestionsItemTitle}>
      {parse(keyword.replace(regex, '<strong>$&</strong>'))}
    </div>
    <button
      onClick={handleDelete}
      className={cn(styles.suggestionsItemIcon, styles.suggestionsItemButton)}
    >
      <SvgIcon name="times" />
    </button>
  </a>
);

const HeaderSearch = ({
  category = null,
  categoriesTopLevel,
  categoriesIcons,
}) => {
  const searchRef = React.useRef(null);
  const [toggleSearchCategoriesModal, setToggleSearchCategoriesModal] = React.useState(false);
  const { keywords: keywordsParam = '' } = useQueryParams();
  const [keywords, setKeywords] = React.useState(keywordsParam);
  const [keywordsType, setKeywordsType] = React.useState(null);
  const keywordsHelperRef = React.useRef(null);
  const keywordsRef = React.useRef(null);
  const client = useApolloClient();
  const [searchCategory, setSearchCategory] = React.useState(category);
  const [searchSuggestionsToggle, setSearchSuggestionsToggle] = React.useState(false);
  const [isTouched, setIsTouched] = React.useState(false);
  const [suggestions, setSuggestions] = React.useState([]);
  const [suggestionsList, setSuggestionsList] = React.useState(null);
  const [suggestionsFocus, setSuggestionsFocus] = React.useState(null);
  const [suggestionsData, setSuggestionsData] = React.useState([]);
  const [keywordsHelperToggle, setKeywordsHelperToggle] = React.useState(false);
  const [keywordsHistory, setKeywordsHistory] = React.useState(null);
  const [searchPlaceholder, setSearchPlaceholder] = React.useState('Искать на OptWear');
  const { isMedia } = useMedia();
  useScrollFixed(isMedia('md') && searchSuggestionsToggle);
  const setKeywordsHelper = () => {
    if (keywordsHelperRef.current.value && keywordsHelperToggle) {
      setKeywordsHelperToggle(false);
      setKeywords(keywordsHelperRef.current.value);
    }
  };

  const deleteKeywordsLocalStorage = (e, value) => {
    e.preventDefault();
    if (keywordsHistory.length === 0) return null;

    const currentKeywordsHistory = keywordsHistory.filter((keyword) => keyword !== value);
    setKeywordsHistory(currentKeywordsHistory);
    localStorage.setItem('searchKeywordsHistory', JSON.stringify(currentKeywordsHistory));
    setKeywordsType(null);
    setSuggestionsFocus(null);
  };
  const searchSuggestionsList = React.useCallback(
    (newSuggestions, currentSearchCategory, focusedIndex = null) => {
      if (suggestions.length === 0) setSuggestionsList(null);
      const context = [];
      const every = {
        lists: {
          history: {
            label: '',
            items: [],
          },
          word: {
            label: '',
            items: [],
          },
          category: {
            label: 'Категории',
            items: [],
          },
          lot: {
            label: 'Лоты',
            items: [],
          },
          purchase: {
            label: 'Закупки',
            items: [],
          },
          owner: {
            label: 'Организаторы',
            items: [],
          },
        },
        count: 0,
      };

      newSuggestions.forEach((suggestion, index) => {
        const { Item } = suggestion;
        if (suggestion.type === 'context') {
          context.push(<Item
            key={index}
            focus={index === focusedIndex}
          />);
        } else {
          every.lists[suggestion.type].items.push(
            <Item
              key={index}
              focus={index === focusedIndex}
            />,
          );
          every.count++;
        }
      });

      const EverySuggestions = () => {
        if (every.count === 0) return null;

        const Lists = () => (
          <>
            {Object.keys(every.lists)
              .map((listKey, index) => {
                if (every.lists[listKey].items.length === 0) return null;

                return every.lists[listKey].label ? (
                  <div
                    className={styles.suggestionsSublist}
                    key={index}
                  >
                    <div className={styles.suggestionsLabel}>
                      {every.lists[listKey].label}
                    </div>
                    {every.lists[listKey].items}
                  </div>
                ) : (
                  every.lists[listKey].items
                );
              })}
          </>
        );

        return context.length !== 0 ? (
          <div className={styles.suggestionsSublist}>
            <div className={styles.suggestionsLabel}>Везде</div>
            <Lists />
          </div>
        ) : (
          <Lists />
        );
      };

      setSuggestionsList(
        <>
          {context.length !== 0 && (
            <div className={cn(styles.suggestionsSublist)}>
              <div className={styles.suggestionsLabel}>
                {'Категория '}
                {currentSearchCategory.category_name}
              </div>
              {context}
            </div>
          )}
          <EverySuggestions />
        </>,
      );
    },
    [suggestionsFocus],
  );

  const filtersSearchSuggestions = React.useCallback(
    (currentKeywords, currentSuggestionsData, currentSearchCategory = searchCategory) => {
      const keywordsRegex = new RegExp(`^${_escapeRegExp(currentKeywords)}`, 'g');
      const newSuggestions = [];
      let searchHistoryKeywords = [];
      let currentKeywordsHelper = null;

      if (!_isNull(currentSearchCategory) && currentKeywords) {
        newSuggestions.push({
          type: 'context',
          keywords: currentKeywords,
          link: `/category/${currentSearchCategory.category_id}?keywords=${urlParamEscape(
            currentKeywords,
          )}`,
          Item: ({ focus }) => (
            <SearchSuggestionItem
              title={`Поиск «${currentKeywords}» в категории ${currentSearchCategory.category_name}`}
              data={() => <strong>{currentKeywords}</strong>}
              link={`/category/${
                currentSearchCategory.category_id
              }?keywords=${urlParamEscape(currentKeywords)}`}
              meta={() => <SvgIcon name="search" />}
              focus={focus}
            />
          ),
        });
      }

      if (keywordsHistory.length !== 0) {
        searchHistoryKeywords = keywordsHistory
          .filter((keyword) => keywordsRegex.test(keyword))
          .sort((a, b) => a.length - b.length || a.localeCompare(b));
        if (searchHistoryKeywords.length !== 0) {
          searchHistoryKeywords.forEach((keyword, index) => {
            if (
              _isNull(currentKeywordsHelper)
              && keyword !== currentKeywords
              && keywordsRegex.test(keyword)
            ) {
              currentKeywordsHelper = keyword;
            }
            newSuggestions.push({
              type: 'history',
              keywords: keyword,
              link: `/search?keywords=${urlParamEscape(keyword)}`,
              Item: ({ focus }) => (
                <SearchSuggestionHistory
                  focus={focus}
                  key={index}
                  keyword={keyword}
                  regex={keywordsRegex}
                  handleDelete={(e) => deleteKeywordsLocalStorage(e, keyword)}
                />
              ),
            });
          });
        }
      }

      if (currentSuggestionsData) {
        if (
          !!currentSuggestionsData.SuggestWords
          && currentSuggestionsData.SuggestWords.length !== 0
        ) {
          const newSuggestWords = _filter(
            currentSuggestionsData.SuggestWords,
            (suggest) => !searchHistoryKeywords.includes(suggest.word),
          );
          if (newSuggestWords.length !== 0) {
            newSuggestWords
              .slice(0, newSuggestWords.length - searchHistoryKeywords.length)
              .forEach((suggest, index) => {
                if (
                  _isNull(currentKeywordsHelper)
                  && suggest.word !== currentKeywords
                  && keywordsRegex.test(suggest.word)
                ) {
                  currentKeywordsHelper = suggest.word;
                }
                newSuggestions.push({
                  type: 'word',
                  keywords: suggest.word,
                  link: `/search?keywords=${urlParamEscape(suggest.word)}`,
                  Item: ({ focus }) => (
                    <SearchSuggestionItem
                      focus={focus}
                      key={index}
                      title={suggest.word}
                      data={() => (
                        <>
                          {parse(
                            suggest.word.replace(
                              keywordsRegex,
                              '<strong>$&</strong>',
                            ),
                          )}
                        </>
                      )}
                      link={`/search?keywords=${urlParamEscape(
                        suggest.word,
                      )}`}
                      meta={() => <SvgIcon name="search" />}
                    />
                  ),
                });
              });
          }
        }

        if (
          !!currentSuggestionsData.SuggestCategories
          && currentSuggestionsData.SuggestCategories.length !== 0
        ) {
          currentSuggestionsData.SuggestCategories.forEach((currentCategory) => {
            newSuggestions.push({
              type: 'category',
              keywords: currentCategory.category_name,
              link: `/category/${
                currentCategory.category_id
              }?keywords=${urlParamEscape(currentKeywords)}`,
              Item: ({ focus }) => (
                <SearchSuggestionItem
                  focus={focus}
                  key={currentCategory.category_id}
                  title={currentCategory.category_name}
                  data={() => currentCategory.category_name}
                  link={`/category/${
                    currentCategory.category_id
                  }?keywords=${urlParamEscape(currentKeywords)}`}
                  meta={() => <SvgIcon name="stream" />}
                />
              ),
            });
          });
        }

        if (
          !!currentSuggestionsData.SuggestLots
          && currentSuggestionsData.SuggestLots.length !== 0
        ) {
          currentSuggestionsData.SuggestLots.forEach((lot) => {
            newSuggestions.push({
              type: 'lot',
              keywords: lot.lot_name,
              link: `/item/${lot.lot_id}`,
              Item: ({ focus }) => (
                <SearchSuggestionItem
                  focus={focus}
                  key={lot.lot_id}
                  title={lot.lot_name}
                  data={() => (
                    <>
                      {parse(
                        lot.lot_name.replace(
                          keywordsRegex,
                          '<strong>$&</strong>',
                        ),
                      )}
                      <br />
                      <strong>{formatPrice(lot.lot_price)}</strong>
                    </>
                  )}
                  link={`/item/${lot.lot_id}`}
                  meta={() => (
                    <div className={styles.suggestionsItemImage}>
                      <img
                        onError={imagePreloaderSrc}
                        className={cn(
                          stylesCommon.imageFit,
                          stylesCommon.absoluteCenter,
                        )}
                        src={lot.lot_image_thumb}
                      />
                    </div>
                  )}
                />
              ),
            });
          });
        }
        if (
          !!currentSuggestionsData.SuggestPurchases
          && currentSuggestionsData.SuggestPurchases.length !== 0
        ) {
          currentSuggestionsData.SuggestPurchases.forEach((purchase) => {
            newSuggestions.push({
              type: 'purchase',
              keywords: purchase.purchase_name,
              link: seoUrlCustom('t', purchase.topic_id, '.html'),
              Item: ({ focus }) => (
                <SearchSuggestionItem
                  focus={focus}
                  key={purchase.purchase_id}
                  title={purchase.purchase_name}
                  data={() => parse(
                    purchase.purchase_name.replace(
                      keywordsRegex,
                      '<strong>$&</strong>',
                    ),
                  )}
                  link={seoUrlCustom('t', purchase.topic_id, '.html')}
                  meta={() => <SvgIcon name="list-alt" />}
                />
              ),
            });
          });
        }

        if (
          !!currentSuggestionsData.SuggestOwner
          && currentSuggestionsData.SuggestOwner.length !== 0
        ) {
          currentSuggestionsData.SuggestOwner.map((owner) => newSuggestions.push({
            type: 'owner',
            keywords: owner.username,
            link: `/forum/?&org=${owner.user_id}#purchases-list`,
            Item: ({ focus }) => (
              <SearchSuggestionItem
                focus={focus}
                key={owner.user_id}
                title={owner.username}
                data={() => parse(
                  owner.username.replace(
                    keywordsRegex,
                    '<strong>$&</strong>',
                  ),
                )}
                link={`/forum/?&org=${owner.user_id}#purchases-list`}
                meta={() => (
                  <div
                    className={cn(
                      styles.suggestionsItemImage,
                      'avatar',
                    )}
                  >
                    <img
                      onError={imagePreloaderSrc}
                      className={cn(
                        stylesCommon.imageFit,
                        stylesCommon.absoluteCenter,
                      )}
                      src={owner.avatar}
                    />
                  </div>
                )}
              />
            ),
          }));
        }
      }

      let currentFocus = suggestionsFocus;
      if (_isNull(currentKeywordsHelper)) {
        keywordsHelperRef.current.value = '';
      } else {
        keywordsHelperRef.current.value = currentKeywordsHelper;
        setKeywordsHelperToggle(true);
      }
      if (suggestions !== newSuggestions) {
        setSuggestions(newSuggestions);
        setSuggestionsFocus(null);
        currentFocus = null;
        setKeywordsType(null);
      }
      searchSuggestionsList(newSuggestions, currentSearchCategory, currentFocus);

      if (newSuggestions.length !== 0 && isTouched) {
        setSearchSuggestionsToggle(true);
      }
    },
    [keywordsHistory, searchCategory, suggestionsFocus, suggestions],
  );

  useDebounce(
    async () => {
      if (
        keywords
        && keywords.length > 1
        && !_isNull(keywordsHistory)
        && _isNull(keywordsType)
      ) {
        const { data } = await client.query({
          query: GET_SUGGESTION_ALL,
          variables: {
            keywords,
            limit: 3,
            wordsLimit: 5,
          },
        });
        filtersSearchSuggestions(keywords, data);
        setSuggestionsData(data);
      }
    },
    250,
    [keywordsType, keywords],
  );

  useClickAway(searchRef, () => {
    if (searchSuggestionsToggle) {
      setSearchSuggestionsToggle(false);
    }
  });

  const currentSearchCategory = React.useMemo(() => category, []);

  const saveKeywordsLocalStorage = (value) => {
    let currentKeywordsHistory = keywordsHistory;
    if (keywordsHistory.includes(value)) return null;

    if (keywordsHistory.length >= 15) {
      currentKeywordsHistory = currentKeywordsHistory.slice(1);
    }

    currentKeywordsHistory.push(value);

    setKeywordsHistory(currentKeywordsHistory);
    localStorage.setItem('searchKeywordsHistory', JSON.stringify(currentKeywordsHistory));
  };

  const cleanKeywordsLocalStorage = () => {
    setKeywordsHistory([]);
    localStorage.setItem('searchKeywordsHistory', JSON.stringify([]));
    setSearchSuggestionsToggle(false);
    setKeywordsType(null);
    setSuggestionsFocus(null);
  };

  const handleResetKeywords = () => {
    setKeywords('');

    if (isMedia('md')) {
      keywordsRef.current.focus();
    } else if (keywordsHistory.length === 0) {
      setSearchSuggestionsToggle(false);
    }
  };

  const handleResetSearchCategory = () => {
    setSearchCategory(null);
    if (searchSuggestionsToggle && isTouched) {
      filtersSearchSuggestions(keywords, suggestionsData, null);
    }
  };

  const handleMobileCloseSuggestions = () => {
    setSearchSuggestionsToggle(false);
    // setSuggestions([]);
  };

  const handleSubmit = () => {
    const submitKeywords = keywords;

    if (submitKeywords && submitKeywords.length > 1) {
      if (!_isNull(keywordsType)) {
        switch (keywordsType) {
          case 'history-all': {
            window.location = `/search?keywords=${urlParamEscape(
              keywordsHistory.slice()
                .reverse()[suggestionsFocus],
            )}`;
            break;
          }
          default: {
            window.location = suggestions[suggestionsFocus].link;
          }
        }
      } else {
        const location = searchCategory
          ? `/category/${searchCategory.category_id}`
          : '/search';

        saveKeywordsLocalStorage(submitKeywords);
        window.location = `${
          isMedia('md') ? '/search' : location
        }?keywords=${urlParamEscape(submitKeywords)}`;
      }
    }
  };

  const handleChangeSearchCategory = (newCategory) => {
    setSearchCategory(newCategory);
    setToggleSearchCategoriesModal(false);
    if (isTouched) {
      filtersSearchSuggestions(keywords, suggestionsData, newCategory);
    }
  };

  const setFocusBySuggestions = React.useCallback(
    (direction = 'down') => {
      let newIndex;
      if (suggestions.length === 0) {
        if (keywordsHistory.length === 0) return false;

        if (_isNull(suggestionsFocus)) {
          if (direction === 'down') {
            newIndex = 0;
          } else {
            newIndex = keywordsHistory.length - 1;
          }
        } else if (direction === 'down') {
          newIndex = suggestionsFocus + 1 >= keywordsHistory.length ? 0 : suggestionsFocus + 1;
        } else {
          newIndex = suggestionsFocus - 1 < 0
            ? keywordsHistory.length - 1
            : suggestionsFocus - 1;
        }

        setKeywordsType('history-all');
        setKeywords(keywordsHistory.slice()
          .reverse()[newIndex]);
      } else {
        if (_isNull(suggestionsFocus)) {
          if (direction === 'down') {
            newIndex = 0;
          } else {
            newIndex = suggestions.length - 1;
          }
        } else if (direction === 'down') {
          newIndex = suggestionsFocus + 1 >= suggestions.length ? 0 : suggestionsFocus + 1;
        } else {
          newIndex = suggestionsFocus - 1 < 0 ? suggestions.length - 1 : suggestionsFocus - 1;
        }

        setKeywordsType(suggestions[newIndex].type);
        setKeywords(suggestions[newIndex].keywords);
        searchSuggestionsList(suggestions, searchCategory, newIndex);
      }

      setSuggestionsFocus(newIndex);
    },
    [suggestions, suggestionsFocus, keywordsHistory],
  );

  const handleKeyUpInput = (e) => {
    switch (e.key) {
      case 'Enter': {
        handleSubmit();
        break;
      }
      case 'ArrowRight': {
        keywords
        && keywords.length === keywordsRef.current.selectionStart
        && setKeywordsHelper();
        break;
      }
      case 'ArrowDown': {
        setFocusBySuggestions();
        break;
      }
      case 'ArrowUp': {
        setFocusBySuggestions('up');
        break;
      }
      default: {
        return false;
      }
    }
  };

  const handleChangeKeywords = (e) => {
    const { value } = e.target;
    !_isNull(keywordsType) && setKeywordsType(null);
    const keywordsHelperRegex = new RegExp(`^${_escapeRegExp(value)}`, 'g');
    if (value && value.length <= 1) {
      setSuggestions([]);
      if (!isMedia('md')) {
        if (keywordsHistory.length === 0) {
          setSearchSuggestionsToggle(false);
        }
      }
    }
    if (!isMedia('md') && value.length > 1) {
      setSearchSuggestionsToggle(true);
    }
    setKeywordsHelperToggle(keywordsHelperRegex.test(keywordsHelperRef.current.value));
    setKeywords(value);
  };

  const handleFocusKeywords = () => {
    !isTouched && setIsTouched(true);
    if (isMedia('md')) {
      if (!searchSuggestionsToggle) {
        setSearchSuggestionsToggle(true);
      }
    } else if (
      (!keywords && keywordsHistory.length !== 0)
      || (keywords && keywords.length > 1)
    ) {
      setSearchSuggestionsToggle(true);
    }
  };

  const handleFocusKeywordsHelper = () => {
    if (!isMedia('md')) {
      setKeywordsHelper();
    }
    keywordsRef.current.focus();
  };

  useMount(() => {
    if (_isNull(keywordsHistory)) {
      const currentKeywordsHistory = localStorage.getItem('searchKeywordsHistory');

      setKeywordsHistory(
        _isNull(currentKeywordsHistory) || !currentKeywordsHistory
          ? []
          : !Array.isArray(JSON.parse(localStorage.getItem('searchKeywordsHistory')))
            ? []
            : JSON.parse(localStorage.getItem('searchKeywordsHistory')),
      );
    }
    setSearchPlaceholder(
      `Искать ${
        _isNull(searchCategory) || isMedia('md')
          ? 'на OptWear'
          : `в ${searchCategory.category_name}`
      }`,
    );
  });

  return (
    <div
      className={cn(styles.main, searchSuggestionsToggle && isTouched && 'open-suggestions')}
      ref={searchRef}
    >
      <MediaWrap lessThan="md">
        <span className={cn(styles.title, 'above-md')}>Поиск</span>
        <span
          className={styles.suggestionsClose}
          role="button"
          tabIndex={-1}
          onClick={handleMobileCloseSuggestions}
        >
          Закрыть
        </span>
      </MediaWrap>
      <div className={styles.form}>
        {_isNull(searchCategory) ? (
          <div
            role="listbox"
            className={styles.select}
            tabIndex={-1}
            onClick={() => setToggleSearchCategoriesModal(true)}
          >
            <div className={styles.selectText}>Везде</div>
            <div className={styles.selectIcon}>
              <SvgIcon name="caret-down" />
            </div>
          </div>
        ) : (
          <div
            className={cn(styles.select, 'active')}
            title={searchCategory.category_name}
          >
            <div className={styles.selectText}>{searchCategory.category_name}</div>
            <button
              className={styles.selectBtn}
              onClick={handleResetSearchCategory}
            >
              <SvgIcon name="times" />
            </button>
          </div>
        )}
        <div className={styles.field}>
          <div className={cn(styles.fieldMobileIcon, 'above-md')}>
            <SvgIcon name="search" />
          </div>
          <Input
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
            ref={keywordsRef}
            onKeyUp={handleKeyUpInput}
            className={styles.input}
            onChange={handleChangeKeywords}
            value={keywords}
            onFocus={handleFocusKeywords}
            placeholder={searchPlaceholder}
          />
          <input
            ref={keywordsHelperRef}
            type="text"
            readOnly={true}
            className={styles.fieldHelper}
            style={{
              visibility:
                searchSuggestionsToggle
                && keywordsHelperToggle
                && suggestions.length !== 0
                && keywords
                && _isNull(searchCategory)
                && _isNull(setKeywordsType)
                  ? 'visible'
                  : 'hidden',
            }}
            onFocus={handleFocusKeywordsHelper}
          />
          <button
            className={cn(styles.reset, !!keywords && 'show')}
            onClick={handleResetKeywords}
          >
            <SvgIcon name="times" />
          </button>
        </div>

        <Button
          onClick={handleSubmit}
          size="lg"
          className={styles.button}
        >
          <SvgIcon name="search" />
        </Button>
      </div>
      <div className={styles.suggestions}>
        {!_isNull(keywordsHistory) && (
          <div className={styles.suggestionsList}>
            {keywords && suggestions.length !== 0
              ? suggestionsList
              : keywordsHistory.length !== 0
              && (!keywords
                || _isNull(keywords)
                || keywordsType === 'history-all') && (
                <>
                  <div className={styles.suggestionsHistory}>
                    <div className={styles.suggestionsLabel}>
                      История запросов
                    </div>
                    <span
                      role="button"
                      tabIndex={-1}
                      className={styles.suggestionsHistoryClean}
                      onClick={cleanKeywordsLocalStorage}
                    >
                      Очистить
                    </span>
                  </div>

                  {keywordsHistory
                    .slice()
                    .reverse()
                    .map((keyword, index) => (
                      <SearchSuggestionHistory
                        focus={index === suggestionsFocus}
                        key={index}
                        keyword={keyword}
                        handleDelete={(e) => deleteKeywordsLocalStorage(e, keyword)}
                      />
                    ))}
                </>
              )}
          </div>
        )}
      </div>
      <Modal
        name="searchCategoriesModal"
        isOpen={toggleSearchCategoriesModal}
        onClose={() => setToggleSearchCategoriesModal(false)}
        className={styles.modalMore}
      >
        <ModalMain>
          <div className={styles.searchCategories}>
            {!!currentSearchCategory && (
              <div className={styles.searchCategoriesCurrent}>
                <div
                  onClick={() => handleChangeSearchCategory(null)}
                  className={styles.searchCategoriesItem}
                  role="button"
                  tabIndex={-1}
                  title="Везде"
                >
                  <div className={styles.searchCategoriesItemIcon}>
                    <SvgIcon name="bars" />
                  </div>
                  <div className={styles.searchCategoriesItemLabel}>Везде</div>
                </div>
                <div
                  onClick={() => handleChangeSearchCategory(currentSearchCategory)}
                  className={styles.searchCategoriesItem}
                  title={currentSearchCategory.category_name}
                  role="button"
                  tabIndex={-1}
                >
                  <div className={styles.searchCategoriesItemIcon}>
                    <SvgIcon name="search" />
                  </div>
                  <div className={styles.searchCategoriesItemLabel}>
                    {currentSearchCategory.category_name}
                  </div>
                </div>
              </div>
            )}

            {categoriesTopLevel.categories.map((currentCategory) => (
              <div
                onClick={() => handleChangeSearchCategory(currentCategory)}
                className={styles.searchCategoriesItem}
                key={currentCategory.category_id}
                title={currentCategory.category_name}
                role="button"
                tabIndex={-1}
              >
                <div className={styles.searchCategoriesItemIcon}>
                  <SvgIcon name={categoriesIcons(currentCategory.category_id)} />
                </div>
                <div className={styles.searchCategoriesItemLabel}>
                  {currentCategory.category_name}
                </div>
              </div>
            ))}
          </div>
        </ModalMain>
      </Modal>
    </div>
  );
};

export default HeaderSearch;
