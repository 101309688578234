import React from 'react';
import cn from 'classnames';
import { Switch, useRouteMatch } from 'react-router';
import { Route } from 'react-router-dom';
import _isNull from 'lodash/isNull';

import CategoriesButtonModule from '@JsReactModules/CategoriesButton/CategoriesButtonModule';
import HeaderSearch from '@JsReactModules/Header/modules/HeaderSearch/HeaderSearch';
import {
  GET_CATEGORIES_TOP_LEVEL_QUERY,
  GET_CATEGORY_BY_ID_QUERY,
} from '@Js/api/categories/queries';
import { useFixedUseQuery } from '@JsReact/helpers/hooks';
import Button from '@JsReact/ui/controls/Button/Button';
import SvgIcon from '@JsComponents/utils/SvgIcon';
import styles from './styles/header-module.react-module.scss';

const HeaderModuleApp = () => {
  const { data: categoriesTopLevelData } = useFixedUseQuery(GET_CATEGORIES_TOP_LEVEL_QUERY);
  const categoryMatch = useRouteMatch('/category/:category_id');
  const { data: categoryByIdData } = useFixedUseQuery(GET_CATEGORY_BY_ID_QUERY, {
    skip: _isNull(categoryMatch),
    variables: {
      ...(!_isNull(categoryMatch) && {
        category_id: parseInt(categoryMatch.params.category_id, 10),
      }),
    },
  });

  const categoriesTopLevelIcons = React.useCallback((id) => {
    const icons = {
      54419: 'blender-phone',
      54423: 'books',
      54432: 'tshirt',
      54418: 'tire',
      54496: 'paw',
      54420: 'chair-office',
      54440: 'mobile',
      54421: 'duck',
      54425: 'computer-speaker',
      73590: 'turkey',
      54422: 'house',
      54438: 'medkit',
      54436: 'volleyball',
    };

    return {}.propertyIsEnumerable.call(icons, id) ? icons[id] : null;
  }, []);

  if (!categoriesTopLevelData) return null;

  return (
    <div className={styles.main}>
      <CategoriesButtonModule
        categoriesTopLevelData={categoriesTopLevelData}
        categoriesIcons={categoriesTopLevelIcons}
      />
      <HeaderSearch
        categoriesTopLevel={categoriesTopLevelData}
        categoriesIcons={categoriesTopLevelIcons}
        category={categoryByIdData && categoryByIdData.categories[0]}
      />
      <a
        href="/forum"
        className={cn(styles.logo, 'above-md')}
      >
        <img
          src="/forum/images/logo-sp.svg"
          alt=""
        />
      </a>
      <Button
        id="_headerProfileMobileBtnShow"
        className={styles.userBtn}
        type="button"
      >
        <SvgIcon name="user-fill" />
      </Button>
    </div>
  );
};

const HeaderModule = ({ props }) => (
  <Switch>
    <Route path="*">
      <HeaderModuleApp {...props} />
    </Route>
  </Switch>
);

export default HeaderModule;
