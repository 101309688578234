import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from '../Modal.react-module.scss';

const ModalFooter = ({
  className,
  children,
}) => (
  <div className={cn(styles.footer, className)}>
    {children}
  </div>
);

ModalFooter.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default ModalFooter;
