import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import stylesCommon from '@StylesJson/common.module.json';
import _capitalize from 'lodash/capitalize';
import Label from '@JsReact/ui/controls/Label/Label';

const Checkbox = ({
	checked,
	noVisual = false,
	theme = 'primary',
	size = 'md',
	title = null,
	className = {},
	children,
	...props
}) => {
	return (
		<label
			title={title}
			className={cn(
				stylesCommon.checkbox,
				stylesCommon[`checkboxTheme${_capitalize(theme)}`],
				stylesCommon[`checkboxSize${_capitalize(size)}`],
				checked && 'checked',
				noVisual && 'no-visual',
				...className
			)}
		>
			<input
				type={'checkbox'}
				className={stylesCommon.checkboxOriginal}
				checked={checked}
				{...props}
			/>
			<Label component={'span'} size={size} className={stylesCommon.checkboxLabel}>
				{children}
			</Label>
		</label>
	);
};

Checkbox.propsTypes = {
	label: PropTypes.string.isRequired,
	theme: PropTypes.oneOf(['primary', 'secondary', 'success']),
	size: PropTypes.oneOf(['xs', 'sm', 'md']),
};

export default Checkbox;
