import React from 'react';

import { useLocation } from 'react-router-dom';
import { useIntersection, useWindowSize } from 'react-use';
import { useLazyQuery, useQuery } from '@apollo/client';
import _throttle from 'lodash/throttle';
import { scrollFixed } from '@JsReact/helpers/client';
import checkMedia from '@Js/scripts/Media';
import { isSSR } from '@Js/scripts/Utils';

export const useQueryParams = (search = null) => {
  const locationSearch = useLocation().search || search;
  const [queryParams, setQueryParams] = React.useState(new URLSearchParams(locationSearch));

  React.useEffect(() => {
    setQueryParams(new URLSearchParams(locationSearch));
  }, [locationSearch]);

  return { ...Object.fromEntries(queryParams) };
};

export const useAwaitFirstLoadingSSR = (loading) => {
  const [init, setInit] = React.useState(false);

  React.useEffect(() => {
    if (!init && !loading) {
      setInit(true);
    }
  }, [loading]);

  return [init];
};

export const useFixedUseQuery = (query, options, showAll = false, mergeFn) => {
  const [init] = useAwaitFirstLoadingSSR();
  const queryInstance = useQuery(query, options);
  const {
    data,
    loading,
    ...rest
  } = queryInstance;
  const prevData = React.useRef(data);
  const [reduceData, setReduceData] = React.useState(undefined);

  React.useEffect(() => {
    if (data) {
      prevData.current = data;

      if (!showAll) {
        setReduceData(data);
      } else if (showAll && mergeFn) {
        setReduceData(mergeFn(data, reduceData));
      }
    }
  }, [data]);

  return {
    data: showAll ? reduceData : data || prevData.current,
    loading: init ? loading : false,
    ...rest,
  };
};

export const useFixedUseLazyQuery = (query, options) => {
  const [getData, {
    data,
    loading,
    ...rest
  }] = useLazyQuery(query, options);
  const prevData = React.useRef(data);

  React.useEffect(() => {
    if (data) {
      prevData.current = data;
    }
  });

  return [
    getData,
    {
      data: data || prevData.current,
      loading,
      ...rest,
    },
  ];
};

export const useImageZoom = (src) => {
  const [state, setState] = React.useState({
    backgroundImage: `url(${src})`,
    backgroundPosition: '0% 0%',
    top: '50%',
    left: '50%',
  });

  const handleMouseMove = (e) => {
    if (e) {
      const {
        left,
        top,
        width,
        height,
      } = e.target.getBoundingClientRect();
      const x = ((e.pageX - left) / width) * 100;
      const y = ((e.pageY - top) / height) * 100;
      setState({
        ...state,
        // backgroundPosition: `${x}% ${y}%`
        top: `${y}%`,
        left: `${x}%`,
      });
    }
  };
  const test = _throttle(handleMouseMove, 10000);
  // 	const throttleHandleMouseMove = React.useCallback(e => {
  // 		const { left, top, width, height } = e.target.getBoundingClientRect();
  // 		console.log(left);
  // e.persist();
  // 		return _throttle(handleMouseMove
  // 			, 1000)
  // 	},
  // 		[]
  // 	);

  return {
    styles: state,
    event: test,
  };
};

export const useIntersectionObserverQuery = (query, options, observerOptions = {}) => {
  const [getData, {
    data,
    loading,
  }] = useLazyQuery(query, options);
  const [isShow, setIsShow] = React.useState(false);
  const intersectionRef = React.useRef(null);
  const intersection = useIntersection(intersectionRef, {
    root: null,
    rootMargin: '0px',
    threshold: 0,
    ...observerOptions,
  });

  React.useEffect(() => {
    if (!data && !loading && intersection && intersection.isIntersecting) {
      setIsShow(true);
      getData();
    }
  }, [intersection]);

  return {
    ref: intersectionRef,
    data,
    loading,
    isShow,
  };
};

export const useScrollFixed = (toggle, durationStart = 0, durationEnd = 0) => {
  const [init, setInit] = React.useState(false);

  React.useEffect(() => {
    if (toggle && !init) {
      setInit(true);
    }
    if (init) {
      if (toggle) {
        setTimeout(() => {
          scrollFixed(true);
          document.documentElement.classList.add('document-block');
        }, durationStart);
      } else {
        setTimeout(() => {
          scrollFixed(false);
          document.documentElement.classList.remove('document-block');
        }, durationEnd);
      }
    }
  }, [toggle, init]);
};

export const useMedia = (fallback = false) => {
  const { width } = useWindowSize();

  // const backspace = React.useCallback((breakpoint) => {
  // 	if (isSSR())
  // });

  const isMedia = React.useCallback((breakpoint) => (isSSR() ? fallback : checkMedia(breakpoint)), [width]);

  return {
    isMedia,
  };
};

export const useHover = ({
  enterDelay = 0,
  leaveDelay = 0,
}) => {
  const [value, setValue] = React.useState(false);
  const ref = React.useRef(null);
  let mouseEnterTimer;
  let mouseOutTimer;
  const handleMouseEnter = () => {
    clearTimeout(mouseOutTimer);
    mouseEnterTimer = setTimeout(() => setValue(true), enterDelay);
  };
  const handleMouseLeave = () => {
    clearTimeout(mouseEnterTimer);
    mouseOutTimer = setTimeout(() => setValue(false), leaveDelay);
  };

  React.useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener('mouseenter', handleMouseEnter);
        node.addEventListener('mouseleave', handleMouseLeave);
        return () => {
          node.removeEventListener('mouseenter', handleMouseEnter);
          node.removeEventListener('mouseleave', handleMouseLeave);
        };
      }
    },
    [ref.current], // Recall only if ref changes
  );
  return [ref, value];
};
