import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from '../../Modal.react-module.scss';

const ModalHeader = ({
  className,
  children,
}) => (
  <div className={cn(styles.header, className)}>
    {children}
  </div>
);

ModalHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default ModalHeader;
