import React from 'react';
import cn from 'classnames';
import stylesCommon from '@StylesJson/common.module.json';
import _capitalize from 'lodash/capitalize';

const Form = React.forwardRef(function Form(props, ref) {
	const { size = 'md', children, className = {}, ...rest } = props;
	return (
		<form
			className={cn(
				stylesCommon.form,
				stylesCommon[`formSize${_capitalize(size)}`],
				...className
			)}
			{...rest}
			ref={ref}
		>
			{children}
		</form>
	);
});

// const Form = ({size = 'md', children, className = {}, ...props}) => {
// const {size = 'md', children, className = {}, ...rest} = props;
// 	return (
// 		<form className={cn(
// 			stylesCommon.form,
// 			stylesCommon['formSize' + _capitalize(size)],
// 			...className
// 		)} {...rest}>
// 			{children}
// 		</form>
// 	);
// };

export default Form;
