
	// constructor() {
	// 	this.style = getComputedStyle(document.documentElement);
	// 	this.mobile = window.matchMedia(`(max-width: ${this._get('xs')})`).matches;
	// 	this.tablet = window.matchMedia(`(max-width: ${this._get('md')})`).matches;
	// 	this.tablet_desktop = window.matchMedia(`(max-width: ${this._get('lg', 'min')}) and (min-width: ${this._get('md')})`).matches;
	// 	this.desktop = window.matchMedia(`(min-width: ${this._get('md', 'min')})`).matches;
	// }

	const _get = (style, breakpoint, direction = 'max') => {
		switch (direction) {
			case 'max':
				const width = parseInt(style.getPropertyValue(`--breakpoint-${breakpoint}`), 10) - 1;
				return width + 'px';
			case 'min':
				return style.getPropertyValue(`--breakpoint-${breakpoint}`);
		}
	};


const checkMedia = (breakpoint) => {
	// const style = getComputedStyle(document.documentElement);
	let match = false;

	switch (breakpoint) {
		case 'mobile':
		case 'xs':
			match = window.matchMedia(`(max-width: ${575}px)`).matches;
			break;
		case 'tablet':
		case 'md':
			match = window.matchMedia(`(max-width: ${991}px)`).matches;
			break;
		case 'tablet_desktop':
		case 'md-lg':
			match = window.matchMedia(`(min-width: ${992}px) and (max-width: ${1199}px)`).matches;
			break;
		case 'desktop':
		case 'lg':
			match = window.matchMedia(`(min-width: ${992}px)`).matches;
			break;
		default:
			match = false;
			console.error('Передан неверный breakpoint!');
	}

	return match;
};

export default checkMedia;
