import React from 'react';
import stylesCommon from '@StylesJson/common.module.json';

const TextInLine = ({ children }) => {
	return (
		<div className={stylesCommon.decorTextInLine}>
			<div className={stylesCommon.decorTextInLineWrapper}>
				<span className={stylesCommon.decorTextInLineText}>{children}</span>
			</div>
		</div>
	);
};

export default TextInLine;
