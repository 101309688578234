import React from 'react';
import cn from 'classnames';
import _capitalize from 'lodash/capitalize';

import stylesCommon from '@StylesJson/common.module.json';

const FormGroup = ({ size = 'md', inline = false, className, children, ...props }) => {
	return (
  <div
    className={cn(
				stylesCommon.formGroup,
				stylesCommon[`formGroupSize${_capitalize(size)}`],
				inline && stylesCommon.formGroupInline,
				className
			)}
    {...props}
    data-ui-control="form-group"
		>
    {children}
  </div>
	);
};

export default FormGroup;
