import React from 'react';
import cn from 'classnames';

import AuthForm from '@JsReactComponents/AuthForm/AuthForm';
import Modal from '@JsReact/ui/components/Modal/Modal';
import { AuthContext, constantsAuthReducer } from '@JsReact/helpers/context/auth';
import styles from './styles/auth-modal.react-module.scss';

const uLoginScriptSrc = '//ulogin.ru/js/ulogin.js';

const AuthModal = ({
  modalOpen,
  modalOnClose,
  modalInfo: ModalInfo = null,
  ...props
}) => {
  const {
    state,
    dispatch,
  } = React.useContext(AuthContext);

  React.useEffect(() => {
    if (modalOpen && !state.uLoginLoad) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = uLoginScriptSrc;
      script.onload = () => setTimeout(() => {
        dispatch({
          type: constantsAuthReducer.U_LOGIN_LOADED_AUTH_MODAL,
        });
      }, 1500);
      script.onerror = () => modalOnClose();
      document.body.appendChild(script);
    }
  }, [modalOpen]);

  return (
    <Modal
      name="authFormModal"
      isOpen={modalOpen && state.uLoginLoad}
      onClose={modalOnClose}
      className={styles.modal}
    >
      <div className={cn(styles.main, !!ModalInfo && 'info')}>
        {!!ModalInfo && (
          <div className={styles.infoWrapper}>
            <ModalInfo />
          </div>
        )}
        <div className={styles.content}>
          <h3 className={styles.title}>Вход</h3>
          <AuthForm {...props} />
        </div>
      </div>
    </Modal>
  );
};

export default AuthModal;
