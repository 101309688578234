import React from 'react';
import { CSSTransition } from 'react-transition-group';
import PropTypes from 'prop-types';

import { isSSR } from '@Js/scripts/Utils';
import ModalPortal from '@JsReact/ui/components/Modal/ModalPortal';
import { useScrollFixed } from '@JsReact/helpers/hooks';

const DURATION = 250;

const Modal = ({
  isOpen,
  onClose,
  isLoading,
  ...props
}) => {
  const [opened, setOpened] = React.useState(false);
  useScrollFixed(opened);

  React.useEffect(() => {
    setOpened(isOpen);
  }, [isOpen]);

  const handleClose = React.useCallback(() => {
    setOpened(false);
    setTimeout(() => {
      if (onClose) {
        onClose();
      }
    }, DURATION);
  }, []);

  if (isSSR()) return null;

  return (
    <CSSTransition
      in={opened}
      classNames="modal-transition"
      unmountOnExit={true}
      timeout={DURATION}
    >
      <ModalPortal
        duration={DURATION}
        onClose={handleClose}
        isLoading={isLoading}
        {...props}
      />
    </CSSTransition>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  overlayClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
};

Modal.defaultProps = {
  isLoading: false,
};

export default Modal;
