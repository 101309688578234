import React from 'react';
import cn from 'classnames';
import _capitalize from 'lodash/capitalize';
import ReCAPTCHA from 'react-google-recaptcha';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import qs from 'qs';

import Form from '@JsReact/ui/controls/Form/Form';
import FormGroup from '@JsReact/ui/controls/FormGroup/FormGroup';
import Input from '@JsReact/ui/controls/Input/Input';
import Checkbox from '@JsReact/ui/controls/Checkbox/Checkbox';
import TextInLine from '@JsReact/ui/utils/TextInLine';
import Button from '@JsReact/ui/controls/Button/Button';
import Preloader from '@JsReact/ui/heplers/Preloader';
import SvgIcon from '@JsComponents/utils/SvgIcon';
import stylesCommon from '@StylesJson/common.module.json';
import FieldError from '@JsReact/ui/controls/FieldError/FieldError';
import styles from './styles/auth-form.react-module.scss';

const AuthForm = () => {
  const recaptchaRef = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const [login, setLogin] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [recaptcha, setRecaptcha] = React.useState(false);

  const validationSchema = React.useCallback(() => Yup.object().shape({
    username: Yup.string().required('Обязательное поле!'),
    password: Yup.string().required('Обязательное поле!'),
    ...(recaptcha && {
      google_recaptcha_token: Yup.string().required('Обязательное поле!'),
    }),
  }), [recaptcha]);
  const handleExternalLink = (e, name) => {
    e.preventDefault();
    e.stopPropagation();

    document.querySelector(`#uLoginReact div[data-uloginbutton='${name}']`).click();
  };

  const getExternalLinks = () => {
    const links = [];
    const externalContainer = document.querySelector('#uLoginReact .ulogin-buttons-container');

    externalContainer.children.forEach((link, index) => {
      const name = link.getAttribute('data-uloginbutton');
      links.push(
        <li
          key={index}
          className={cn(
            styles.extenralsItem,
            stylesCommon.socialLink,
            stylesCommon[`socialLink${_capitalize(name)}`],
          )}
          onClick={(e) => handleExternalLink(e, name)}
        >
          <SvgIcon name={name} />
        </li>,
      );
    });

    return links;
  };

  const {
    handleSubmit,
    handleChange,
    values,
    errors,
    submitCount,
    setFieldValue,
  } = useFormik({
    initialValues: {
      username: '',
      password: '',
      autologin: true,
      ...(recaptcha && { google_recaptcha_token: '' }),
    },
    validationSchema,
    onSubmit(submitValues) {
      setLoading(true);
      recaptcha && recaptchaRef.current.reset();
      axios
        .post(
          '/forum/ajax.php',
          qs.stringify({
            ...submitValues,
            login: true,
            task: 'login',
            option: 'optwear\\AjaxRequest',
          }),
        )
        .then(({ data }) => {
          if (data.login === true && !data.error) {
            setLogin(true);
            setTimeout(() => {
              window.location.reload();
            }, 1500);
          } else {
            if (data.captcha) {
              setRecaptcha(true);
            }
            setError(data.error);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
  });

  return (
    <Form className={styles.form} onSubmit={handleSubmit}>
      {!!error && (
        <div className={styles.error}>
          <div className={styles.errorIcon}>
            <SvgIcon name="danger-circe" />
          </div>
          <div className={styles.errorIcon}>{error}</div>
        </div>
      )}
      <FormGroup>
        <Input
          error={!!submitCount && errors.username}
          name="username"
          type="text"
          onChange={handleChange}
          value={values.username}
          placeholder="Логин"
        />
      </FormGroup>
      <FormGroup>
        <Input
          error={!!submitCount && errors.password}
          name="password"
          type="password"
          onChange={handleChange}
          value={values.password}
          placeholder="Пароль"
        />
      </FormGroup>
      {recaptcha && (
        <FormGroup>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey="6Lct-7QZAAAAAOunhUfZGZP7zFKVmBktT4Cced2H"
            onChange={(value) => setFieldValue('google_recaptcha_token', value)}
          />
          <FieldError
            toggle={!!submitCount && !!errors.google_recaptcha_token}
            text={errors.google_recaptcha_token}
          />
        </FormGroup>
      )}
      <FormGroup size="lg" inline={true}>
        <Checkbox
          size="xs"
          name="autologin"
          onChange={handleChange}
          checked={values.autologin}
        >
          Оставаться в системе
        </Checkbox>
        <a href="/forum/ucp.php?mode=sendpassword" className={styles.forget}>
          Забыли пароль?
        </a>
      </FormGroup>
      <FormGroup>
        <Button type="submit">Войти</Button>
      </FormGroup>
      <TextInLine>Или используйте сторонние сервисы</TextInLine>
      <div className={styles.extenrals}>
        <ul className={styles.extenralsList}>{getExternalLinks()}</ul>
      </div>

      <div className={styles.register}>
        У Вас ещё нет аккаунта?
        <a className={styles.registerLink} href="/forum/ucp.php?mode=register">
          {'\u00A0'}
          {'Создайте его!'}
        </a>
      </div>
      <Preloader style={{ backgroundColor: '#fff' }} loading={loading} />
      {login && (
        <div className={styles.login}>
          <div className={cn(styles.loginIcon, styles.loginIconSuccess)}>
            <SvgIcon name="check-circle" />
          </div>
          <span className={styles.loginText}>
            Вы успешно авторизовались!
            <br />
            Хороших покупок!
          </span>
        </div>
      )}
    </Form>
  );
};

export default AuthForm;
