import { createMedia } from '@artsy/fresnel';

const WrapperMedia = createMedia({
	breakpoints: {
		lg: 1200,
		md: 992,
		sm: 768,
		xs: 576,
		xxs: 0,
	},
});

export const mediaStyles = WrapperMedia.createMediaStyle();

export const { MediaContextProvider, Media } = WrapperMedia;
