import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';

import styles from '../Modal.react-module.scss';

const ModalMain = ({
  className,
  wrapperClassName,
  children,
  disabledScroll,
}) => (
  <div
    className={cn(styles.main, className, {
      'disabled-scroll': disabledScroll,
    })}
  >
    <SimpleBar autoHide={false}>
      <div className={cn(styles.mainWrapper, wrapperClassName)}>
        {children}
      </div>
    </SimpleBar>
  </div>
);

ModalMain.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  disabledScroll: PropTypes.bool,
};

export default ModalMain;
