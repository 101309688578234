import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from '../../Modal.react-module.scss';

const ModalHeaderTitle = ({
  className,
  children,
}) => (
  <div className={cn(styles.headerTitle, className)}>
    {children}
  </div>
);

ModalHeaderTitle.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default ModalHeaderTitle;
