import React from 'react';

export const AuthContext = React.createContext();

export const constantsAuthReducer = {
  SHOW_AUTH_MODAL: 'SHOW_AUTH_MODAL',
  HIDDEN_AUTH_MODAL: 'HIDDEN_AUTH_MODAL',
  U_LOGIN_LOADED_AUTH_MODAL: 'U_LOGIN_LOADED_AUTH_MODAL',
};

export const initialStateAuthReducer = {
  toggleAuthModal: false,
  authModalInfo: null,
  uLoginLoad: false,
};

export const authReducer = (state, action) => {
  switch (action.type) {
    case constantsAuthReducer.SHOW_AUTH_MODAL: {
      return {
        ...state,
        toggleAuthModal: true,
        ...(action.authInfo && { authModalInfo: action.authInfo }),
      };
    }
    case constantsAuthReducer.HIDDEN_AUTH_MODAL: {
      return {
        ...state,
        toggleAuthModal: false,
        authModalInfo: null,
      };
    }
    case constantsAuthReducer.U_LOGIN_LOADED_AUTH_MODAL: {
      return {
        ...state,
        uLoginLoad: true,
      };
    }
    default:
      return state;
  }
};
