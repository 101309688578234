import React from 'react';
import cn from 'classnames';
import { useApolloClient, useLazyQuery } from '@apollo/client';
import { useClickAway } from 'react-use';
import { Scrollbars } from 'react-custom-scrollbars';
import _isNull from 'lodash/isNull'
import _set from 'lodash/set'
import _isEmpty from 'lodash/isEmpty'

import Button from '@JsReact/ui/controls/Button/Button';
import SvgIcon from '@JsComponents/utils/SvgIcon';
import Preloader from '@JsReact/ui/heplers/Preloader';
import { useMedia, useScrollFixed } from '@JsReact/helpers/hooks';
import MediaWrap from '@JsReact/ui/heplers/MediaWrap';
import {
  GET_SEARCH_FILTERS_CATEGORIES_NESTED_QUERY,
  GET_SEARCH_FILTERS_CATEGORIES_QUERY,
} from '@Js/api/search/queries';
import staticData from '@Json/export/categories-button-module.json';
import { CSSTransition } from 'react-transition-group';
import styles from './assets/categories-button-module.react-module.scss';

const LIST_ANIMATION_TRANSITION = 250;

const TopLevelCategoryIconNavBtn = ({
  icons,
  id,
}) => {
  const iconName = icons(id);

  return _isNull(iconName) ? null : (
    <div className={styles.navBtnIcon}>
      <SvgIcon name={iconName} />
    </div>
  );
};

const MobileNavigationList = ({
  category,
  section,
  handle,
  parents = [],
  categoriesIcons = null,
  mobileNavigation,
}) => {
  const items = [];
  let navigation = { ...mobileNavigation[section] };

  const Category = () => (
    <>
      <div className={styles.navBtn}>
        {section === 'categories' && (
          <TopLevelCategoryIconNavBtn icons={categoriesIcons} id={category.id} />
        )}
        {
          section !== 'categories' && category.icon && (
            <div className={styles.navBtnIcon}>
              <SvgIcon name={category.icon} />
            </div>
          )
        }
        <div className={styles.navBtnLabel}>{category.label}</div>
        <div className={styles.navBtnCheck}>
          <SvgIcon name="check" />
        </div>
      </div>
      {!_isNull(category.children) && (
        <div className={styles.navList}>
          {Object.keys(category.children)
            .map((childrenCategoryKey) => (
              <button
                key={childrenCategoryKey}
                className={styles.navBtn}
                onClick={() => handle(section, childrenCategoryKey, category.children, [
                  ...parents,
                  category.id,
                ])}
              >
                <div className={styles.navBtnLabel}>
                  {category.children[childrenCategoryKey].label}
                </div>
              </button>
            ))}
        </div>
      )}
    </>
  );
  const newParents = [];

  parents.forEach((parent) => {
    const currentNavigation = { ...navigation };
    const currentCategory = { ...currentNavigation[parent] };
    const currentParents = [...newParents];

    items.push(
      <button
        key={parent}
        key-parents={currentParents}
        className={styles.navBtn}
        onClick={() => handle(section, parent, currentNavigation, currentParents)}
      >
        {section === 'categories' && (
          <TopLevelCategoryIconNavBtn icons={categoriesIcons} id={parent} />
        )}
        {
          section !== 'categories' && currentCategory.icon && (
            <div>{currentCategory.icon}</div>
          )
        }
        <div className={styles.navBtnLabel}>{currentCategory.label}</div>
      </button>,
    );
    navigation = navigation[parent].children;
    if (parent !== 0) {
      newParents.push(parent);
    }
  });

  return (
    <>
      <button
        className={styles.navBtn}
        onClick={() => handle(section, null, mobileNavigation[section])}
      >
        <div className={styles.navBtnIcon}>
          <SvgIcon name="angle-left" />
        </div>
        <div className={styles.navBtnLabel}>Все</div>
      </button>
      <div className={styles.navList}>
        {items.length === 0 ? (
          <Category />
        ) : (
          <>
            {items
              .slice()
              .reverse()
              .reduce(
                (accumulator, current) => (
                  <>
                    {current}
                    <div className={styles.navList}>{accumulator}</div>
                  </>
                ), <Category />,
              )}
            {' '}
          </>
        )}
      </div>
    </>
  );
};

const CategoriesButtonModule = ({
  categoriesTopLevelData,
  categoriesIcons,
}) => {
  const refMain = React.useRef(null);
  const client = useApolloClient();
  const [
    getChildrenCategories,
    {
      loading: childrenCategoriesLoading,
      data: childrenCategoriesData,
    },
  ] = useLazyQuery(GET_SEARCH_FILTERS_CATEGORIES_NESTED_QUERY);

  const { isMedia } = useMedia();
  const [mobileNavigation, setMobileNavigation] = React.useState({
    categories: {},
    productcat: {},
  });
  const [currentMobileNavigationPosition, setCurrentMobileNavigationPosition] = React.useState({
    section: null,
    id: null,
    navigation: null,
    parents: [],
  });
  const [state, setState] = React.useState({
    open: false,
    blockHeight: 0,
    currentParentCategory: null,
    parentCategoryHover: false,
  });
  const [mobileNavigationItems, setMobileNavigationItems] = React.useState([]);
  const [categoriesChildrenLoading, setCategoriesChildrenLoading] = React.useState(false);
  useClickAway(refMain, () => {
    state.open
    && setState({
      ...state,
      open: false,
    });
  });
  useScrollFixed(state.open);

  const handleToggle = (toggle) => {
    setState({
      ...state,
      open: toggle,
    });
  };

  const handleClose = () => {
    setCurrentMobileNavigationPosition({
      section: null,
      id: null,
      navigation: null,
      parents: [],
    });
    handleToggle(false);
  };
  const onHoverParentCategory = (id) => {
    state.currentParentCategory !== id
    && setState({
      ...state,
      currentParentCategory: id,
    });
  };

  const handleClickNavBtn = (section, id, navigation, parents = []) => {
    setCurrentMobileNavigationPosition({
      ...currentMobileNavigationPosition,
      section,
      navigation,
      parents,
      id,
    });
  };

  const handleMobileNavigationBack = () => {
    setMobileNavigationItems(null);
    setCurrentMobileNavigationPosition({
      ...currentMobileNavigationPosition,
      section: null,
      id: null,
      navigation: null,
      parents: [],
    });
  };
  const getDeepCategory = React.useCallback((id, categories) => {
    let currentCategory;
    const getCategory = (category) => {
      if (category.id === id) {
        currentCategory = category;
      } else {
        getCategory(category.children[0]);
      }
    };
    getCategory(categories[0]);
    return currentCategory;
  }, []);

  const changeMobileNavigationCategories = React.useCallback(
    (categories, currentCategory) => {
      const currentDeep = [];
      const mobileNavigationCopy = { ...mobileNavigation.categories };

      const searchDeep = (deep, navigation, category) => {
        if (category.id !== currentCategory.id) {
          deep.push(`${category.id}.children`);
          searchDeep(deep, navigation[category.id].children, category.children[0]);
        } else {
          const deepStr = [...deep, category.id].join('.');
          _set(mobileNavigationCopy, deepStr, {
            ...navigation[category.id],
            children: currentCategory.children,
          });
        }
      };

      searchDeep(currentDeep, mobileNavigation.categories, categories[0]);

      return mobileNavigationCopy;
    },
    [mobileNavigation],
  );

  const generateNavigation = () => {
    const {
      section,
      id,
      navigation,
      parents,
    } = currentMobileNavigationPosition;

    const items = [];

    if (_isNull(id)) {
      Object.keys(navigation)
        .forEach((productcatKey) => {
          items.push(
            <button
              key={productcatKey}
              className={styles.navBtn}
              onClick={() => handleClickNavBtn(section, productcatKey, navigation)}
            >
              {
                navigation[productcatKey].icon && (
                  <div className={styles.navBtnIcon}>
                    <SvgIcon name={navigation[productcatKey].icon} />
                  </div>
                )
              }
              <div className={styles.navBtnLabel}>{navigation[productcatKey].label}</div>
            </button>,
          );
        });
    } else {
      items.push(
        <MobileNavigationList
          key="navigationList"
          category={navigation[id]}
          section={section}
          handle={handleClickNavBtn}
          parents={parents}
          mobileNavigation={mobileNavigation}
        />,
      );
    }

    return items;
  };

  const generateNavigationCategoriesTree = async () => {
    const {
      section,
      id,
      navigation,
      parents,
    } = currentMobileNavigationPosition;

    const items = [];

    if (_isNull(id)) {
      Object.keys(navigation)
        .forEach((categoryKey) => {
          items.push(
            <button
              key={categoryKey}
              className={styles.navBtn}
              onClick={() => handleClickNavBtn(section, categoryKey, navigation)}
            >
              <TopLevelCategoryIconNavBtn
                icons={categoriesIcons}
                id={navigation[categoryKey].id}
              />
              <div className={styles.navBtnLabel}>{navigation[categoryKey].label}</div>
            </button>,
          );
        });
    } else {
      let currentCategoryNavigation = navigation[id];
      let categories;
      let newMobileNavigation;

      if (
        !_isNull(currentCategoryNavigation.children)
        && currentCategoryNavigation.children.length === 0
      ) {
        setCategoriesChildrenLoading(true);
        const result = await client.query({
          query: GET_SEARCH_FILTERS_CATEGORIES_QUERY,
          variables: {
            category_id: id,
          },
          fetchPolicy: 'no-cache',
        });
        setCategoriesChildrenLoading(false);
        const currentCategory = getDeepCategory(
          parseInt(id, 10),
          result.data.search.filters_category_tree,
        );

        if (_isNull(currentCategory.children) || currentCategory.children.length === 0) {
          categories = null;
        } else {
          const childrenObj = {};
          currentCategory.children.forEach((category) => {
            childrenObj[category.id] = {
              id: category.id,
              label: category.name,
              link: `/category/${category.id}`,
              parent: id,
              children: category.has_child ? [] : null,
            };
          });

          categories = childrenObj;
        }

        currentCategoryNavigation = {
          ...currentCategoryNavigation,
          children: categories,
        };
        newMobileNavigation = {
          ...mobileNavigation,
          categories: {
            ...mobileNavigation.categories,
            ...changeMobileNavigationCategories(
              result.data.search.filters_category_tree,
              currentCategoryNavigation,
              id,
            ),
          },
        };
        setMobileNavigation(newMobileNavigation);
      }

      items.push(
        <MobileNavigationList
          key="navigationList"
          category={currentCategoryNavigation}
          section={section}
          handle={handleClickNavBtn}
          parents={parents}
          mobileNavigation={newMobileNavigation || mobileNavigation}
          categoriesIcons={categoriesIcons}
        />,
      );
    }

    return items;
  };

  React.useEffect(() => {
    if (!state.currentParentCategory && categoriesTopLevelData) {
      const id = categoriesTopLevelData.categories[0].category_id;

      setState({
        ...state,
        currentParentCategory: id,
      });
    }

    if (state.currentParentCategory && state.open && !isMedia('md')) {
      getChildrenCategories({
        variables: {
          category_id: state.currentParentCategory,
        },
      });
    }

    if (_isEmpty(mobileNavigation.categories) && isMedia('md')) {
      const navigation = {};

      categoriesTopLevelData.categories.forEach((category) => {
        navigation[category.category_id] = {
          id: category.category_id,
          label: category.category_name,
          link: `/category/${category.category_id}`,
          parent: 0,
          children: [],
        };
      });

      setMobileNavigation({
        ...mobileNavigation,
        categories: {
          ...mobileNavigation.categories,
          ...navigation,
        },
      });
    }

    if (_isEmpty(mobileNavigation.productcat) && isMedia('md')) {
      const navigation = {};

      staticData.productcat.forEach((cat) => {
        navigation[cat.id] = {
          ...cat,
          children: _isNull(cat.children) ? null : { ...cat.children },
        };
      });

      setMobileNavigation({
        ...mobileNavigation,
        productcat: {
          ...mobileNavigation.productcat,
          ...navigation,
        },
      });
    }

    if (state.open) {
      setState({
        ...state,
        blockHeight: isMedia('md')
          ? '100%'
          : window.innerHeight
          - document.getElementById('siteHeader').clientHeight
          + document.getElementById('siteHeaderBottom').clientHeight
          - 16,
      });
    }

    if (!_isNull(currentMobileNavigationPosition.section)) {
      (async function updateMobileNavigation() {
        if (currentMobileNavigationPosition.section === 'categories') {
          const categories = await generateNavigationCategoriesTree();
          setMobileNavigationItems(categories);
        } else {
          const navigations = generateNavigation();
          setMobileNavigationItems(navigations);
        }
      }());
    }
  }, [
    state.open,
    categoriesTopLevelData,
    state.currentParentCategory,
    currentMobileNavigationPosition,
  ]);

  const handleGoToMobileNavigation = React.useCallback(() => {
    const position = currentMobileNavigationPosition.navigation[currentMobileNavigationPosition.id];

    if (!_isNull(position.link)) {
      window.location = position.link;
    }
  }, [currentMobileNavigationPosition, mobileNavigation]);
  return (
    <div className={styles.main} ref={refMain}>
      <Button
        size="lg"
        className={styles.button}
        onClick={() => handleToggle(!state.open)}
        fill={true}
      >
        <div className={styles.buttonIcon}>
          {state.open && !isMedia('md') ? (
            <SvgIcon name="times" />
          ) : (
            <SvgIcon name="bars" />
          )}
        </div>
        <span className={styles.buttonText}>Категории</span>
      </Button>

      <CSSTransition
        in={state.open}
        classNames="categories-button"
        unmountOnExit={true}
        timeout={LIST_ANIMATION_TRANSITION}
      >
        <div
          className={styles.list}
          style={{ height: state.blockHeight }}
        >
          <div className={styles.header}>
            {!_isNull(currentMobileNavigationPosition.section) && (
              <div
                className={styles.headerBack}
                role="button"
                tabIndex={-1}
                onClick={handleMobileNavigationBack}
              >
                <SvgIcon name="arrow-left" />
              </div>
            )}

            <div className={styles.headerTitle}>Меню</div>
            <div
              className={styles.headerClose}
              role="button"
              tabIndex={-1}
              onClick={handleClose}
            >
              <SvgIcon name="times" />
            </div>
          </div>
          <div className={styles.listContent}>
            <MediaWrap className={styles.listContentMedia} greaterThan="sm">
              <div className={styles.navigation}>
                <Scrollbars
                  universal={true}
                  className={cn('scroll', 'hover')}
                  renderThumbVertical={({
                    style,
                    ...props
                  }) => (
                    <div
                      {...props}
                      style={{ ...style }}
                      className="scroll-vertical-track"
                    />
                  )}
                >
                  <div className={styles.navigationContent}>
                    <ul className={styles.navigationList}>
                      {categoriesTopLevelData
                      && categoriesTopLevelData.categories.map((category) => (
                        <li
                          key={category.category_id}
                          className={cn(
                            styles.navigationListItem,
                            state.currentParentCategory
                              === category.category_id && 'hover',
                          )}
                        >
                          <a
                            href={`/category/${category.category_id}`}
                            title={category.category_name}
                            onMouseEnter={() => onHoverParentCategory(
                              category.category_id,
                            )}
                            className={styles.navigationLink}
                          >
                            <div
                              className={
                                styles.navigationLinkIcon
                              }
                            >
                              <SvgIcon
                                name={categoriesIcons(
                                  category.category_id,
                                )}
                              />
                            </div>
                            <span
                              className={
                                styles.navigationLinkText
                              }
                            >
                              {category.category_name}
                            </span>
                          </a>
                        </li>
                      ))}
                    </ul>
                    <ul className={styles.navigationAdditional}>
                      {staticData.additional.map((data, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <li key={index} className={cn(styles.navigationListItem)}>
                          <a
                            href={data.link}
                            title={data.label}
                            className={styles.navigationLink}
                          >
                            <div className={styles.navigationLinkIcon}>
                              <SvgIcon name={data.icon} />
                            </div>
                            <span className={styles.navigationLinkText}>
                              {data.label}
                            </span>
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Scrollbars>
              </div>

              <div className={cn(styles.categories)}>
                <Scrollbars
                  universal={true}
                  className={cn('scroll', 'hover')}
                  renderThumbVertical={({
                    style,
                    ...props
                  }) => (
                    <div
                      {...props}
                      style={{ ...style }}
                      className="scroll-vertical-track"
                    />
                  )}
                  renderView={(props) => (
                    <div {...props} className={styles.categoriesScroll} />
                  )}
                >
                  <div className={styles.categoriesBanner}>
                    <img
                      src="/forum/images/banners/categoriesButtonModule.png"
                      className={styles.categoriesBannerImg}
                    />
                  </div>
                  <div className={styles.categoriesContent}>
                    {childrenCategoriesData
                    && childrenCategoriesData.search.filters_category_tree[0].children
                      .slice()
                      .sort((a, b) => {
                        if (a.has_child && !b.has_child) {
                          return -1;
                        }
                        if (a.has_child && b.has_child) {
                          return 0;
                        }
                        return 1;
                      })
                      .map((category) => (
                        <div
                          className={styles.categoriesContentList}
                          key={category.id}
                        >
                          <a
                            href={`/category/${category.id}`}
                            className={
                              styles.categoriesContentListTitle
                            }
                          >
                            {category.name}
                          </a>
                          {category.has_child && (
                            <ul
                              className={
                                styles.categoriesContentSublist
                              }
                            >
                              {category.children.map(
                                (childrenCategory) => (
                                  <li
                                    className={
                                      styles.categoriesContentSublistItem
                                    }
                                    key={
                                      childrenCategory.id
                                    }
                                  >
                                    <a
                                      href={`/category/${childrenCategory.id}`}
                                      className={
                                        styles.categoriesContentSublistLink
                                      }
                                    >
                                      <span
                                        className={
                                          styles.categoriesContentSublistLinkText
                                        }
                                      >
                                        {
                                          childrenCategory.name
                                        }
                                      </span>
                                    </a>
                                  </li>
                                ),
                              )}
                            </ul>
                          )}
                        </div>
                      ))}
                    <Preloader
                      loading={childrenCategoriesLoading}
                      style={{ backgroundColor: '#fff' }}
                    />
                  </div>
                </Scrollbars>
              </div>
            </MediaWrap>
            <MediaWrap className={styles.listContentMedia} lessThan="md">
              <div className={styles.mobileNav}>
                <Preloader
                  loading={categoriesChildrenLoading}
                  style={{ backgroundColor: 'rgba(255, 255, 255, .5)' }}
                />

                {mobileNavigation.categories.length === 0 ? (
                  <Preloader style={{ backgroundColor: '#fff' }} />
                ) : (
                  <>
                    {_isNull(currentMobileNavigationPosition.section) ? (
                      <>
                        <div className={styles.categoriesBanner}>
                          <img
                            src="/forum/images/banners/categoriesButtonModule.png"
                            className={styles.categoriesBannerImg}
                          />
                        </div>
                        <button
                          className={cn(styles.categoriesBtn, styles.navBtn)}
                          onClick={() => handleClickNavBtn(
                            'categories',
                            null,
                            mobileNavigation.categories,
                          )}
                        >
                          <div className={styles.navBtnIcon}>
                            <SvgIcon name="stream" />
                          </div>
                          <div className={styles.navBtnLabel}>
                            Категории товаров
                          </div>
                        </button>
                        <button
                          className={cn(styles.purchasesBtn, styles.navBtn)}
                          onClick={() => handleClickNavBtn(
                            'productcat',
                            null,
                            mobileNavigation.productcat,
                          )}
                        >
                          <div className={styles.navBtnIcon}>
                            <SvgIcon name="list-alt" />
                          </div>
                          <div className={styles.navBtnLabel}>
                            Категории закупок
                          </div>
                        </button>
                        {staticData.additional.map((data, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <a key={index} className={cn(styles.navBtn)} href={data.link}>
                            <div className={styles.navBtnIcon}>
                              <SvgIcon name={data.icon} />
                            </div>
                            <div className={styles.navBtnLabel}>
                              {data.label}
                            </div>
                          </a>
                        ))}
                      </>
                    ) : (
                      mobileNavigationItems
                    )}
                  </>
                )}
                {!_isNull(currentMobileNavigationPosition.id) && (
                  <div className={styles.mobileNavExternal}>
                    <Button
                      className={styles.mobileNavExternalBtn}
                      onClick={handleGoToMobileNavigation}
                    >
                      Перейти
                    </Button>
                  </div>
                )}
              </div>
            </MediaWrap>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default CategoriesButtonModule;
