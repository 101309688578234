import React from 'react';
import cn from 'classnames';
import stylesCommon from '@StylesJson/common.module.json';
import _capitalize from 'lodash/capitalize';
import FieldError from '@JsReact/ui/controls/FieldError/FieldError';

const Input = React.forwardRef(function Input(props, ref) {
	const { type = 'text', size = 'md', error = null, className = {}, ...other } = props;

	return (
		<>
			<input
				ref={ref}
				className={cn(
					stylesCommon.input,
					stylesCommon[`inputSize${_capitalize(size)}`],
					!!error && stylesCommon.inputInvalid,
					...className
				)}
				type={type}
				{...other}
			/>
			<FieldError toggle={!!error} text={error} />
		</>
	);
});

export default Input;
