import React from 'react';
import {
  AuthContext,
  initialStateAuthReducer,
  constantsAuthReducer,
  authReducer,
} from '@JsReact/helpers/context/auth';
import {
  CommonContext,
  initialStateCommonReducer,
  commonReducer,
} from '@JsReact/helpers/context/common';
import AuthModal from '@JsReactComponents/AuthModal/AuthModal';

import { MediaContextProvider } from '@JsReact/helpers/context/media';

const BaseWrapper = ({
  children,
}) => {
  const [stateAuth, dispatchAuth] = React.useReducer(authReducer, initialStateAuthReducer);
  const [stateCommon, dispatchCommon] = React.useReducer(
    commonReducer,
    initialStateCommonReducer,
  );

  return (
    <MediaContextProvider>
      <CommonContext.Provider
        value={{
          dispatch: dispatchCommon,
          state: stateCommon,
        }}
      >
        <AuthContext.Provider
          value={{
            dispatch: dispatchAuth,
            state: stateAuth,
          }}
        >
          {children}

          <AuthModal
            modalOpen={stateAuth.toggleAuthModal}
            modalOnClose={() => {
              dispatchAuth({
                type: constantsAuthReducer.HIDDEN_AUTH_MODAL,
              });
            }}
            modalInfo={stateAuth.authModalInfo}
          />
        </AuthContext.Provider>
      </CommonContext.Provider>
    </MediaContextProvider>
  );
};

export default BaseWrapper;
