import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { isDevelopment } from '@Js/scripts/Utils';

const getToken = () => {
	const token = localStorage.getItem('apiToken');
	return token || '';
};

export const client = (moduleID = '', props = {}, ssr = true) => {
	const httpLink = 	new HttpLink({
		uri: GRAPHQL_API,
		credentials: 'include',
		headers: {
			...((isDevelopment() && getToken()) && {['X-Api-Token']: getToken()})
		}
	});

	const cache = new InMemoryCache({
		typePolicies: {
			SearchFilter: {
				keyFields: ["id", "name"],
			},
		},
	}).restore(window[`__APOLLO_STATE__${moduleID}`]);

	return new ApolloClient({
		connectToDevTools: !PRODUCTION,
		link: httpLink,
		cache: ssr ? cache.restore(window[`__APOLLO_STATE__${moduleID}`]) : cache,
		resolvers: {
			Mutation: props.mutations || {}
		},
		defaultOptions: {
			query: {
				fetchPolicy: 'cache-first',
				errorPolicy: 'all'
			},
			mutate: {
				errorPolicy: 'all'
			},
		},
	});
};

export default client;
